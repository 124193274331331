import { Link } from '@inertiajs/inertia-react'
import React from 'react'

const automationPricing = [
    {
        id: 1,
        title: 'Make.com Hourly',
        name: 'Make.com Hourly $108',
        price: '$108',
        plans: {},
        image: '/frontend/images/svg/icon-16.svg',
        href: 'https://calendly.com/gurulabs/60-min',
        description: 'Hourly services to optimize your Make.com integrations. Enhance your business operations with custom automation solutions.',
        paymentUrl: 'https://calendly.com/gurulabs/60-min',
        features: [
            'One request at a time',
            'Priority support',
            'Advanced integrations',
            'Custom solution design',
            'Regular updates and maintenance'
        ]
    },
    {
        id: 2,
        title: 'Zapier Hourly',
        name: 'Zapier Hourly $108',
        price: '$108',
        plans: {},
        href: 'https://calendly.com/gurulabs/60-min',
        image: '/frontend/images/svg/icon-15.svg',
        description: 'Hourly Consultation to streamline your workflows with Zapier. Get expert assistance in automating your business processes.',
        paymentUrl: 'https://calendly.com/gurulabs/60-min',
        features: [
            'One request at a time',
            'Dedicated support',
            'Custom workflows',
            'Initial setup assistance',
            'Performance monitoring'
        ],
    },
    {
        id: 3,
        title: 'Pabbly Hourly',
        name: 'Pabbly Hourly $108',
        price: '$108',
        plans: {},
        image: '/frontend/images/svg/icon-17.svg',
        href: 'https://calendly.com/gurulabs/60-min',
        description: 'Yearly plan for Pabbly automation consulting. Flexible and comprehensive support to meet your business automation needs.',
        paymentUrl: 'https://calendly.com/gurulabs/60-min',
        features: [
            'One request at a time',
            'Flexible scheduling',
            'Comprehensive support',
            'Data migration assistance',
            'Scalability planning'
        ]
    },
];



const AutomationPricing = ({ modalIsOpen, setModalIsOpen }) => {
    return (
        <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-20 px-2">
                {
                    automationPricing.map((item, index) => (
                        <div className="border-2 border-gray-100 py-6" key={index}>
                            {item.id === 2 && (
                                <div className="bg-green-300 flex justify-center items-center -mt-10">
                                    <h2 className="py-2 text-gray-700">Most Popular</h2>
                                </div>
                            )}
                            <div className="bg-white py-4 px-2">
                                <img src={item.image} className="py-2 px-2" alt={`${item.title}`} />
                                <h2 className="text-xl py-2">{item.title}</h2>
                                <p className="text-gray-700 py-2">{item.description}</p>
                                <h4 className="text-2xl text-gray-900 py-2">{item.price}/hr</h4>
                                <ul className="py-4 px-2">
                                    {item.features.map((feature, index) => (

                                        <li className="flex items-center text-gray-700 py-4 px-2 border-b-2" key={index}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.13 12.024a.765.765 0 0 1-.506-.19L4.852 8.505a.766.766 0 0 1 1.013-1.15l3.236 2.845 6.754-6.706a.768.768 0 1 1 1.079 1.094L9.67 11.81a.768.768 0 0 1-.54.215Z" /><path d="M8.661 17.541a8.502 8.502 0 0 1-8.38-9.88A8.5 8.5 0 0 1 11.388.987a.766.766 0 1 1-.492 1.45 6.969 6.969 0 1 0 4.45 8.581.765.765 0 1 1 1.468.438 8.548 8.548 0 0 1-8.152 6.084Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.161.542h17v17h-17z" /></clipPath></defs></svg>
                                            {feature}
                                        </li>

                                    ))}
                                </ul>
                                <div className="flex items-center text-gray-700 py-2 ml-4 px-2">
                                    {/* <a href={item.paymentUrl} className="button w-full py-4 px-4 text-gray-750 text-center">Book Now {item.price}/hr</a> */}
                                    <button
                                        onClick={() => setModalIsOpen(!modalIsOpen)}
                                        className="relative w-full py-3 px-3 top-1 right-2 text-center button rounted-sm text-gray-700 hover:bg-green-500"
                                    >
                                        <span className="text-xl">Schedule a meeting</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div >
    )
}
export default AutomationPricing
