import React from 'react';
import { Link } from '@inertiajs/inertia-react'
import mixpanel from 'mixpanel-browser'

const pricingPlans = [
    {
        id: 1,
        title: 'Single Setup Plan',
        name: '1 Setup - $4,999',
        price: '$4,999',
        plans: {
            monthly: '$4,999',
        },
        href: 'https://calendly.com/gurulabs/15min',
        paymentUrl: 'https://calendly.com/gurulabs/15min',
        image: '/frontend/images/svg/icon-15.svg',
        description: 'Includes a single setup for either GTM Client Side or Server Side. Everything necessary to get started.',
        features: [
            'GTM Client Side Features',
            'GTM Server Side Features',
            'Complete Setup & Configuration',
            '1 Month Post-Setup Support',
        ],
    },
    {
        id: 2,
        title: 'Dual Setup Plan',
        name: '2 Setups - $8,999',
        price: '$8,999',
        plans: {
            monthly: '$8,999',
        },
        href: 'https://calendly.com/gurulabs/15min',
        paymentUrl: 'https://calendly.com/gurulabs/15min',
        image: '/frontend/images/svg/icon-16.svg',
        description: 'Includes setups for both GTM Client Side and Server Side. Perfect for scaling businesses.',
        features: [
            'GTM Client Side Features',
            'GTM Server Side Features',
            'Complete Setup & Configuration',
            '2 Months Post-Setup Support',
        ],
    },
    {
        id: 3,
        title: 'Enterprise Plan',
        name: 'Enterprise - Contact Us',
        price: 'Contact Us',
        plans: {
            monthly: 'Contact Us',
        },
        href: 'https://calendly.com/gurulabs/15min',
        paymentUrl: 'https://calendly.com/gurulabs/15min',
        image: '/frontend/images/svg/icon-17.svg',
        description: 'Custom enterprise-grade setup tailored for your business needs. Schedule a meeting to discuss.',
        features: [
            'Custom GTM Client Side Features',
            'Custom GTM Server Side Features',
            'Advanced Analytics Integration',
            'Ongoing Support & Maintenance',
        ],
    },
];

const TagManagerPricing = ({ modalIsOpen, setModalIsOpen }) => {
    return (
        <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-20 px-2">
                {pricingPlans.map((item) => (
                    <div className="border-2 border-gray-100 py-6" key={item.id}>
                        <div className="bg-white py-4 px-2">
                            <img src={item.image} className="py-2 px-2" alt={`${item.title}`} />
                            <h2 className="text-xl py-2">{item.title}</h2>
                            <p className="text-gray-700 py-2">{item.description}</p>
                            <h4 className="text-2xl text-gray-900 py-2">{item.price}</h4>
                            <p className="mt-3 text-sm leading-6 text-gray-700">
                                {item.plans.monthly}
                            </p>
                            <ul className="py-4 px-2">
                                {item.features.map((feature, index) => (
                                    <li
                                        className="flex items-center text-gray-700 py-4 px-2 border-b-2"
                                        key={index}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 mr-2"
                                            fill="none"
                                        >
                                            <g fill="#48BB78" clipPath="url(#a)">
                                                <path d="M9.13 12.024a.765.765 0 0 1-.506-.19L4.852 8.505a.766.766 0 0 1 1.013-1.15l3.236 2.845 6.754-6.706a.768.768 0 1 1 1.079 1.094L9.67 11.81a.768.768 0 0 1-.54.215Z" />
                                                <path d="M8.661 17.541a8.502 8.502 0 0 1-8.38-9.88A8.5 8.5 0 0 1 11.388.987a.766.766 0 1 1-.492 1.45 6.969 6.969 0 1 0 4.45 8.581.765.765 0 1 1 1.468.438 8.548 8.548 0 0 1-8.152 6.084Z" />
                                            </g>
                                            <defs>
                                                <clipPath id="a">
                                                    <path fill="#fff" d="M.161.542h17v17h-17z" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <div className="flex items-center text-gray-700 py-2 ml-4 px-2">
                                {/* <a href={item.paymentUrl} className="button w-full py-4 px-4 text-white text-center">Get Started {item.title}</a> */}
                                <button
                                    onClick={() => {
                                        setModalIsOpen(!modalIsOpen)
                                        mixpanel.track("Schedule a meeting CRO Pricing button clicked");
                                    }}

                                    className="relative w-full py-3 px-3 top-1 right-2 button rounted-sm text-gray-700 hover:bg-green-500"
                                >
                                    <span className="text-xl">Schedule a meeting</span>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TagManagerPricing;
